import axios from "axios";
    

class SirrApp{
	constructor(){  
        this.lng="az";
        this.sort = 'az';
		this.baseUrl = "https://orxideya.looptech.az/api";
		this.baseUrlImage = "https://orxideya.looptech.az/storage/";
        this.headers = {
            Accept:"application/json",
            "Content-Type":"application/json"      
        }   
	};
   
	api(){
        return axios.create({
            baseURL:this.baseUrl,  
            headers: this.headers
                  })
              }
};
const sirr = new SirrApp();
export default sirr      